import * as React from "react";
import { Link } from "gatsby";

// markup
const Success = () => {
  return (
    <main>
      <title> Submission</title>
      <h1>Thank You! You will be added to our newsletter.</h1>
      <p>
        <Link to="/">Go home</Link>.
      </p>
    </main>
  );
};

export default Success;
